$selected-day-color: #dc2626;

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container-wrap {
  padding: 0 100px !important;
}

.container-wrap-secondary {
  padding: 0 150px !important;
}

// this will be extended to childs
.toastify_common {
  font-size: 1.3rem;
  // font-family: "Work Sans";
  font-family: "Sofia Sans", "sans-serif", "!important",
}

.Toastify__toast-theme--colored.Toastify__toast--error {
  @extend .toastify_common;
  background-color: #e63535 !important;
}

.Toastify__progress-bar--error {
  background-color: #ff8080 !important;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  @extend .toastify_common;
  background-color: #05a660 !important;
}
.Toastify__progress-bar--success {
  background-color: #57eba1 !important;
}

.Toastify__toast-theme--colored.Toastify__toast--warning {
  @extend .toastify_common;
  background-color: #ffcc00 !important;
  color: rgb(95, 83, 83) !important;
}
.Toastify__toast-theme--colored.Toastify__toast--info {
  @extend .toastify_common;
  background-color: #0063f7 !important;
}

.MuiAutocomplete-listbox li {
  background: #fff !important ;
  &.Mui-focused {
    background: rgb(233, 233, 233) !important;
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.breadcrumb {
  // z-index: 5;
  display: flex;
  font-family: "Sofia Sans", "sans-serif";
  align-self: flex-start;
  margin-top: auto;
  // margin-left: 82px;
  li {
    list-style: none;
    color: #fff;
    font-weight: 700;
  }
  a {
    text-decoration: none;
    color: #a3a3a3;
  }
  li + li {
    &::before {
      content: ">";
      margin: 0 4px;
      color: #fff;
    }
  }
}

.breadcrumb.breadcrumb-secondary {
  margin-bottom: "11px";
  li {
    color: #2d2d2d;
  }
  a {
    color: #656565;
  }
  li + li {
    &::before {
      color: #656565;
    }
  }
}

.container {
  max-width: 1332px;
  margin-inline: auto;
}

/* .body-row {
  display: flex;
  justify-content: space-between;
  padding: 50px 0;
} */

// calendar
.react-datepicker {
  font-family: "Sofia Sans", "sans-serif";
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  border: none;
}

.react-datepicker__header {
  background-color: #fff;
  border-bottom: none;
}

.react-datepicker__current-month {
  color: #ef4444;
  font-weight: 700;
  font-size: 18px;
  padding: 16px 0px 20px;
}

.react-datepicker__navigation--next {
  right: 30px;
  top: 15px;
}

.react-datepicker__navigation--previous {
  left: 30px;
  top: 15px;
}

.react-datepicker__navigation-icon::before {
  border-color: #000;
  right: -5px;
  left: -5px;
}

.react-datepicker__navigation {
  box-shadow: 0px 1px 1px rgb(0 0 0 / 10%), 0px 0px 0px 1px rgb(70 79 96 / 16%);
}

.react-datepicker__day-names {
  padding: 24px 53px;
  display: flex;
  justify-content: space-around;
  @media (max-width: 500px) {
    padding: 20px 15px;
  }
}

.react-datepicker__week {
  padding: 0 53px;
  .react-datepicker__day + .react-datepicker__day {
    margin-left: 11px;
  }
  @media (max-width: 500px) {
    padding: 0 15px;
  }
}

.react-datepicker__day-name {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  color: #687182;
}

.react-datepicker__day {
  width: 40px;
  height: 40px;
  line-height: 2.5rem;
  @media (max-width: 500px) {
    width: 30px;
    height: 30px;
    margin: 0;
  }
}

.react-datepicker__day--highlighted {
  background-color: $selected-day-color;
  &:hover {
    background-color: darken($color: $selected-day-color, $amount: 10);
  }
}
